/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import '../styles/Menu.scss'

// APP Icons
import { ReactComponent as Comments } from 'assets/icons/comments.svg'
import { ReactComponent as Calendar } from 'assets/icons/calendaralt.svg'
import { ReactComponent as Database } from 'assets/icons/database.svg'
import { ReactComponent as Robot } from 'assets/icons/robot.svg'

// Configuration Icons
import { ReactComponent as Layer } from 'assets/icons/layer.svg'
import { ReactComponent as ContactsCard } from 'assets/icons/address-card.svg'
import { ReactComponent as UserAttendants } from 'assets/icons/user-tie.svg'
import { ReactComponent as ServerSectors } from 'assets/icons/server.svg'
import { ReactComponent as Tags } from 'assets/icons/tags.svg'
import { ReactComponent as Channels } from 'assets/icons/plug.svg'

// ADM
import { ReactComponent as Plan } from 'assets/icons/price-tag.svg'
import { ReactComponent as People } from 'assets/icons/people.svg'
import { ReactComponent as Seller } from 'assets/icons/owner.svg'
import { ReactComponent as Invoice } from 'assets/icons/price-tag-money.svg'
import { ReactComponent as Statistic } from 'assets/icons/report-svgrepo-com.svg'
import { ReactComponent as Dashboard } from 'assets/icons/business-presentation-report-meeting-finance-svgrepo-com.svg'

import { getTagList } from 'panel/tag/redux/actions'
import { getBotList } from 'panel/chatbot/redux/action/bot'
import { getSectorList } from 'panel/sector/redux/actions'
import { getChannelList } from 'panel/channel/redux/actions'
import { getMessageList } from 'panel/message-database/redux/actions'
import { getScheduleList } from 'panel/schedule/redux/actions'
import { ApplicationState } from 'AppReducer'
import { getAttendantList } from 'panel/attendant/redux/actions'

const Menu: React.FC = () => {
  const dispatch = useDispatch()

  const logged = useSelector((state: ApplicationState) => state.userReducer.logged)
  const selected = useSelector((state: ApplicationState) => state.storeReducer.selected)
  const schedules = useSelector((state: ApplicationState) => state.scheduleReducer.schedules)
  const conversations = useSelector((state: ApplicationState) => state.conversationReducer.conversations)

  const isAdmin = !!logged?.roles?.find((role) => role === 'ROLE_ADMIN' || role === 'ROLE_SELLER')
  const isClient = !!logged?.roles?.includes('ROLE_CLIENT')
  const canViewReport = !!logged?.permissions?.includes('REPORT:VIEW')

  const [menuApp, setMenuApp] = useState([
    {
      icon: Comments,
      to: '/panel/conversation',
      label: 'Atendimentos',
      quantity: 0,
      isActive: !!selected,
      roles: ['ROLE_CLIENT'],
      permissions: ['CONVERSATION:MENU']
    },
    {
      icon: Calendar,
      to: '/panel/schedule',
      label: 'Agendamentos',
      quantity: 0,
      isActive: !!selected,
      roles: ['SCHEDULE_MANAGER'],
      permissions: ['SCHEDULE:MENU']
    },
    {
      icon: Robot,
      to: '/panel/chatbot',
      label: 'ChatBot',
      isActive: !!selected,
      roles: ['CHAT_BOT_MANAGER'],
      permissions: ['CHAT_BOT:MENU']
    },
    {
      icon: Database,
      to: '/panel/message-database',
      label: 'Base de Mensagens',
      isActive: !!selected,
      roles: ['MESSAGE_DATABASE_MANAGER'],
      permissions: ['MESSAGE_DATABASE:MENU']
    }
  ])

  const [menuConfig, setMenuConfig] = useState([
    {
      icon: Channels,
      to: '/panel/channel',
      label: 'Canais',
      isActive: !!selected,
      roles: ['CHANNEL_MANAGER'],
      permissions: ['CHANNEL:MENU']
    },
    {
      icon: ServerSectors,
      to: '/panel/sector',
      label: 'Setores',
      isActive: !!selected,
      roles: ['SECTOR_MANAGER'],
      permissions: ['SECTOR:MENU']
    },
    {
      icon: UserAttendants,
      to: '/panel/attendant',
      label: 'Atendentes',
      isActive: !!selected,
      roles: ['ATTENDANT_MANAGER'],
      permissions: ['ATTENDANT:MENU']
    },
    {
      icon: Tags,
      to: '/panel/tag',
      label: 'Etiquetas',
      isActive: !!selected,
      roles: ['TAG_MANAGER'],
      permissions: ['TAG:MENU']
    },
    {
      icon: Layer,
      to: '/panel/group',
      label: 'Grupos',
      isActive: !!selected,
      roles: ['GROUP_MANAGER'],
      permissions: ['GROUP:MENU']
    },
    {
      icon: ContactsCard,
      to: '/panel/person',
      label: 'Contatos',
      isActive: !!selected,
      roles: ['PERSON_MANAGER'],
      permissions: ['PERSON:MENU']
    },
    {
      icon: Invoice,
      to: '/panel/invoice',
      label: 'Meu Plano',
      isActive: !!selected,
      roles: ['INVOICE_MANAGER'],
      permissions: ['INVOICE:MENU']
    }
  ])

  const menuAdmin = [
    { icon: Seller, to: '/panel/seller', label: 'Revendedores', roles: ['ROLE_ADMIN'] },
    { icon: People, to: '/panel/client', label: 'Clientes', roles: ['ROLE_SELLER'] },
    { icon: Seller, to: '/panel/store', label: 'Lojas', roles: ['ROLE_SELLER'] },
    { icon: Plan, to: '/panel/plan', label: 'Planos', roles: ['ROLE_ADMIN'] }
  ]

  useEffect(() => {
    const pendingSchedule = schedules.reduce((acc, item) => { if (item.status === 'PENDING') acc += 1; return acc }, 0)

    setMenuApp(menuApp => menuApp.map(item => {
      if (item.label === 'Agendamentos') {
        item.quantity = pendingSchedule
      }

      if (item.label === 'Atendimentos') {
        item.quantity = conversations.filter(item => item.status === 'QUEUE').length
      }

      return item
    }))

    // eslint-disable-next-line
  }, [schedules, conversations])

  useEffect(() => {
    setMenuApp(items => items.map(item => {
      item.isActive = !!selected
      return item
    }))
    setMenuConfig(items => items.map(item => {
      item.isActive = !!selected
      return item
    }))

    if (selected) {
      // Handle store fetches
      if (logged?.permissions.includes('CHAT_BOT:LIST')) getBotList(selected)(dispatch)
      if (logged?.permissions.includes('SCHEDULE:LIST')) getScheduleList(selected)(dispatch)

      getTagList(selected)(dispatch)
      getSectorList(selected)(dispatch)
      getChannelList(selected)(dispatch)
      getMessageList(selected)(dispatch)
      getAttendantList(selected)(dispatch)
    }
  }, [selected, isClient, dispatch])

  const menuAppFiltered = menuApp
    .filter(item => {
      const hasRole = item.roles.find((str) => logged?.roles?.find(item => item === str))
      const hasPermission = item.permissions.find((str) => logged?.permissions?.find(item => item === str))

      return hasRole || hasPermission
    })

  const menuConfigFiltered = menuConfig
    .filter(item => {
      const hasRole = item.roles.find((str) => logged?.roles?.find(item => item === str))
      const hasPermission = item.permissions.find((str) => logged?.permissions?.find(item => item === str))

      return hasRole || hasPermission
    })

  return (
    <>
      <ul className="menu">
        {
          !!menuAppFiltered.length &&
          <li className="menu-label" style={{ pointerEvents: 'none' }}>
            Aplicativo
          </li>
        }
        {
          menuAppFiltered
            .map((item, i) => {
              const { isActive, icon: Icon } = item

              return (
                <li key={i} style={{ opacity: isActive ? 1 : 0.4, pointerEvents: isActive ? 'inherit' : 'none' }}>
                  <NavLink
                    to={item.to}
                    className="row padding-16"
                    activeClassName="active"
                  >
                    <Icon width={35} height={20} />
                    <section className="label">{item.label}</section>
                    { !!item.quantity && <div className="row quantity">{ item.quantity }</div> }
                  </NavLink>
                </li>
              )
            })
        }
      </ul>

      <ul className="menu margin-top-16">
        {
          !!menuConfigFiltered.length &&
          <li className="menu-label" style={{ pointerEvents: 'none' }}>
            Configurações
          </li>
        }
        {
          menuConfigFiltered
            .map((item, i) => {
              const { isActive, icon: Icon } = item

              return (
                <li key={i} style={{ opacity: isActive ? 1 : 0.4, pointerEvents: isActive ? 'inherit' : 'none' }}>
                  <NavLink
                    exact
                    to={item.to}
                    className="row padding-16"
                    activeClassName="active"
                  >
                    <Icon width={35} height={20} />
                    <section className="label">{item.label}</section>
                  </NavLink>
                </li>
              )
            })
        }
      </ul>

      {
        isAdmin && (
          <ul className="menu margin-top-16">
            <li className="menu-label" style={{ pointerEvents: 'none' }}>
              Administração
            </li>
            {
              menuAdmin
                .filter(item => item.roles.find((str) => logged?.roles?.find(role => role === str)))
                .map((item, i) => {
                  const Icon = item.icon
                  return (
                    <li key={i}>
                      <NavLink
                        to={item.to}
                        className="row padding-16"
                        activeClassName="active"
                      >
                        <Icon width={35} height={20} />
                        <section className="label">{item.label}</section>
                      </NavLink>
                    </li>
                  )
                })
            }
          </ul>
        )
      }

      {
        canViewReport && (
          <ul className="menu margin-top-16">
            <li className="menu-label" style={{ pointerEvents: 'none' }}>
              Relatórios
            </li>

            <li>
              <NavLink exact to="/panel" className="row padding-16" activeClassName="active" >
                <Dashboard width={35} height={20} /> <section className="label">Geral</section>
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/panel/report/statistic" className="row padding-16" activeClassName="active" >
                <Statistic width={35} height={20} /> <section className="label">Estatísticas</section>
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/panel/report/survey" className="row padding-16" activeClassName="active" >
                <Statistic width={35} height={20} /> <section className="label">Pesquisas</section>
              </NavLink>
            </li>
          </ul>
        )
      }
    </>
  )
}

export default Menu
