import React, { memo } from 'react'
import { Handle, Position } from 'reactflow'

const SurveyNode: React.FC = () => {
  return (
    <div className="node px py">
      <p><i className="fa fa-question-circle"/> Pesquisa</p>
      <Handle
        id="survey"
        type="source"
        position={Position.Bottom}
        style={{ bottom: 3, width: 12, height: 12, background: '#ff4081' }}
      />
    </div>
  )
}

export default memo(SurveyNode)
