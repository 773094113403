import { ConversationState, ConversationActionTypes, ConversationTypes, Conversation, Message } from './types'
import { Reducer } from 'redux'

const initialState: ConversationState = {
  pastMessages: {},
  conversation: {},
  conversations: [],
  conversationsFiltered: []
}

type ReducerOpts = Reducer<ConversationState, ConversationActionTypes>
const reducer: ReducerOpts = (state = initialState, action: ConversationActionTypes): ConversationState => {
  let id: number
  let messages: Message[]
  let conversation: Conversation | undefined
  let conversationId: number

  switch (action.type) {
    case ConversationTypes.CONVERSATION_RESET:
      return initialState
    case ConversationTypes.CONVERSATION_GET:
      return { ...state, conversation: { ...state.conversation, [action.payload.id]: action.payload } }
    case ConversationTypes.CONVERSATION_UPDATE:
      return {
        ...state,
        conversation: { ...state.conversation, [action.payload.id]: action.payload },
        conversations: state.conversations.map(item => {
          if (item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
      }
    case ConversationTypes.CONVERSATION_NEW_MESSAGE:
      id = Number(action.id) || 0
      conversation = state.conversation[id]
      messages = conversation?.messages || []

      if (conversation) {
        return {
          ...state,
          conversations: state.conversations.map(conversation => {
            if (conversation.id === id) {
              return { ...conversation, tick: (new Date()).toISOString() }
            }
            return { ...conversation }
          }),
          conversation: {
            ...state.conversation,
            [id]: {
              ...conversation,
              messages: [...messages, action.payload]
            }
          }
        }
      }

      return state
    case ConversationTypes.CONVERSATION_NOTIFICATION:
      conversation = state.conversations.find(item => item.id === action.payload.conversationId)
      if (conversation) {
        if (
          conversation.status === 'RUNNING' &&
          !conversation.attendants.some(item => item.user?.id === action.payload.userId)
        ) {
          return state
        }

        new Notification(action.payload.title, {
          body: action.payload.message,
          icon: action.payload.icon,
          vibrate: [200, 100, 200]
        } as any)
      }

      return state
    case ConversationTypes.CONVERSATION_UPDATE_MESSAGE:
      conversationId = Number(action.conversationId) || 0
      conversation = state.conversation[conversationId]
      messages = conversation?.messages || []

      if (conversation) {
        state = {
          ...state,
          conversations: state.conversations.map(conversation => {
            if (conversation.id === conversationId) {
              return { ...conversation, tick: (new Date()).toISOString() }
            }
            return { ...conversation }
          }),
          conversation: {
            ...state.conversation,
            [conversationId]: {
              ...conversation,
              messages: messages.map(message => {
                if (message.id === action.message.id) {
                  message['status'] = action.message.status || message['status']
                  message['reference'] = action.message.reference || message['reference']

                  message['message'] = action.message.message || message['message']
                  message['previousMessage'] = action.message.previousMessage || message['previousMessage']
                }
                return message
              })
            }
          }
        }
      }

      return state
    case ConversationTypes.CONVERSATION_UPDATE_UNREAD:
      return {
        ...state,
        conversations: state.conversations.map(con => {
          if (con.id === action.conversationId) {
            con.unreadMessages = action.unreadMessages ?? 0
          }
          return con
        })
      }
    case ConversationTypes.CONVERSATION_GET_LIST:
      return { ...state, conversations: action.payload }
    case ConversationTypes.CONVERSATION_GET_LIST_FILTERED:
      return { ...state, conversationsFiltered: action.payload }
    case ConversationTypes.CONVERSATION_PAST_MESSAGE:
      return {
        ...state,
        pastMessages: {
          ...state.pastMessages,
          [action.mainConversationId]: {
            [action.pastConversationId]: action.messages,
            ...(state.pastMessages[action.mainConversationId] || {})
          }
        }
      }
    default:
      return state
  }
}

export default reducer
