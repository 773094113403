/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ApplicationState } from 'AppReducer'
import { LocationPreview } from '../preview/LocationPreview'
import { createMessage } from 'panel/conversation/redux/actions'
import axios from 'axios'
import { Api } from 'util/services/api'

const Location: React.FC = () => {
  const dispatch = useDispatch()

  const [isOpen, setOpen] = useState(false)
  const [isFetching, setFetching] = useState(false)

  const [url, setUrl] = useState('')
  const [message, setMessage] = useState('')
  const [latitude, setLatitude] = useState<number>()
  const [longitude, setLongitude] = useState<number>()

  const { id } = useParams<{ id: string }>()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleClose () {
    setOpen(false)
    setUrl('')
    setMessage('')
    setLatitude(undefined)
    setLatitude(undefined)
    setFetching(false)
  }

  async function handleSubmit (): Promise<void> {
    if (!selected) return

    setFetching(true)

    const formData = new FormData()
    formData.set('message', message)
    formData.set('latitude', String(latitude))
    formData.set('longitude', String(longitude))

    await createMessage(selected, id, formData)(dispatch)
    handleClose()
    setFetching(false)
  }

  const formatUrl = (url: string) => {
    // Regex patterns for extracting meaningful parts of a URL
    const coordsRegex = /@(-?\d+\.\d+),(-?\d+\.\d+)/ // Extracts latitude & longitude
    const placeRegex = /place\/([^/]+)/ // Extracts place name
    const queryRegex = /[?&]q=([^&]+)/ // Extracts query parameter

    let formattedUrl = url // Default to original URL

    // Extract coordinates if present
    const coordsMatch = url.match(coordsRegex)
    if (coordsMatch) {
      const lat = coordsMatch[1]
      const lng = coordsMatch[2]
      formattedUrl = `https://www.google.com/maps?q=${lat},${lng}`
    }

    // Extract place name if present
    const placeMatch = url.match(placeRegex)
    if (placeMatch) {
      const placeName = decodeURIComponent(placeMatch[1].replace(/\+/g, ' '))
      formattedUrl = placeName
    }

    // Extract query parameter if present
    const queryMatch = url.match(queryRegex)
    if (queryMatch) {
      const query = decodeURIComponent(queryMatch[1].replace(/\+/g, ' '))
      formattedUrl = `https://www.google.com/maps?q=${query}`
    }

    return formattedUrl
  }

  function parseURL (url: string) {
    const isGoogleMap = /^https:\/\/www.google.com\/maps/g.test(url)
    if (!isGoogleMap) return false

    let latitude = 0
    let longitude = 0
    const regex = /!3d(-?\d+\.\d+)!4d(-?\d+\.\d+)/g

    let m
    while ((m = regex.exec(url)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }

      m.forEach((match, groupIndex) => {
        if (groupIndex === 1) latitude = Number(match)
        if (groupIndex === 2) longitude = Number(match)
      })
    }

    if (latitude && longitude) {
      setUrl(url)
      setLatitude(latitude)
      setLongitude(longitude)
      if (url) setMessage(formatUrl(url))

      return true
    }
  }

  const pastUrl = async (value: string) => {
    if (!value) return

    const isGoogleMap = /^https:\/\/www.google.com(?:\.br)?\/maps/g.test(value)
    if (isGoogleMap) {
      return parseURL(value)
    }

    const isShort = /^https:\/\/maps.app.goo.gl/g.test(value)
    if (isShort) {
      const { data } = await Api.get('/conversation/helper/unshort-maps-url', { url: value })
      return parseURL(data)
    }

    if (value.includes('http://')) {
      window.open(value, '_blank')
    }
  }
  const handlePaste = async (e: ClipboardEvent) => {
    if (!e.clipboardData) return

    const clipboardValue = e.clipboardData.getData('text')
    pastUrl(clipboardValue)
  }

  const handlePasteByClick = async () => {
    try {
      navigator.permissions
        .query({ name: 'clipboard-read' as any })
        .then(async result => {
          if (result.state === 'granted') {
            const text = await navigator.clipboard.readText()
            pastUrl(text)
          }
          console.log('clipboard-read permission state:', result.state)
        })
    } catch (e) {
      console.error('Clipboard readText error:', e)
    }
  }

  useEffect(() => {
    if (isOpen && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        if (!url) {
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          setLatitude(latitude)
          setLongitude(longitude)
        }
      }, e => console.log, {
        maximumAge: 0, timeout: Infinity, enableHighAccuracy: true
      })
    }
  }, [isOpen])

  useEffect(() => {
    window.addEventListener('paste', handlePaste)

    return () => {
      window.removeEventListener('paste', handlePaste)
    }
  }, [])

  return (
    <>
      {
        isOpen &&
        <div className="preview">
          <div className="row" style={{ flexDirection: 'column' }}>
            <i className="fa fa-times btn-close" onClick={handleClose} />

            <div className="row margin-top-16" style={{ gap: 8, width: 400 }}>
              <a
                target='__blank'
                href='https://www.google.com/maps'
                style={{ fontWeight: 'bold', color: '#069', width: '100%' }}
              >
                Abrir Google maps
              </a>
              <input
                type="text"
                value={url}
                style={{ width: 400 - 8 - 48 }}
                placeholder="Colar URL Google Maps"
                onChange={(e) => setUrl(e.target.value)}
                readOnly
              />
              <span
                onClick={handlePasteByClick}
                title='Colar'
                style={{
                  width: 48,
                  height: 48,
                  display: 'flex',
                  cursor: 'pointer',
                  borderRadius: 5,
                  background: 'gainsboro',
                  placeItems: 'center',
                  placeContent: 'center'
                }}
              >
                <i className="fas fa-paste"></i>
              </span>
            </div>

            <div className="row margin-top-16" style={{ width: 400, borderTop: '1px solid gainsboro', paddingTop: 16 }}>
              <LocationPreview width={400} height={200} latitude={latitude} longitude={longitude} />
              <input
                type="text"
                value={message}
                style={{ width: 400, marginTop: 8 }}
                onChange={e => setMessage(e.target.value)}
                placeholder="Endereço"
              />
            </div>
            <div className="row margin-top-16" style={{ gap: 8 }}>
              <input type="text" value={latitude} style={{ width: 196 }} readOnly placeholder="Latitude" />
              <input type="text" value={longitude} style={{ width: 196 }} readOnly placeholder="Latitude" />
            </div>

            <div className="row margin-top-32">
              <button className="primary" disabled={isFetching} onClick={handleSubmit}>
                {
                  isFetching
                    ? <><i className="fa fa-spin fa-spinner" /> Enviando. Aguarde...</>
                    : <><i className="fa fa-paper-plane" /> Enviar</>
                }
              </button>
            </div>
          </div>
        </div>
      }

      <span className="secondary button location shadow" onClick={() => setOpen(true)}>
        <i className="fas fa-map" />
      </span>
    </>
  )
}

export default Location
