import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as Plan } from 'assets/icons/price-tag.svg'

import { maskMoney } from 'util/masks'
import { ModalHeader } from 'util/modules/Modal'
import { ApplicationState } from 'AppReducer'
import { getAvailablePlans, getFreePlan } from 'panel/plan/redux/actions'
import { createInvoice } from '../redux/actions'
import { useHistory } from 'react-router-dom'

const InvoiceForm: React.FC<{ onClose(): void }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [selectedPlan, setSelectedPlan] = useState(0)

  const { selected, stores } = useSelector((state: ApplicationState) => state.storeReducer)
  const { available } = useSelector((state: ApplicationState) => state.planReducer)

  const [, setFetchingFreePlan] = useState(false)

  const store = stores.find(item => item.id === selected)

  const { freePlan } = useSelector((state: ApplicationState) => state.planReducer)

  function handleCreate () {
    if (selected && selectedPlan) {
      createInvoice(selected, selectedPlan)(dispatch).then(res => {
        if (res?.status === 'success' && res.data) {
          onClose()
          setSelectedPlan(0)

          history.push(`/panel/invoice/${res.data.id}/open`)
        }
      })
    }
  }

  useEffect(() => {
    if (selected) {
      getAvailablePlans(selected)(dispatch)
    }
  }, [selected, dispatch])

  useEffect(() => {
    (async () => {
      setFetchingFreePlan(true)
      await getFreePlan()(dispatch)
      setFetchingFreePlan(false)
    })()
  }, [dispatch])

  const isUserOnFreePlan = store?.plan?.name === 'Plano Gratuito'

  return (
    <div className="row panel no-padding">
      <ModalHeader title="Comprar" onClose={onClose} />
      <div className="row panel-body">

        {
          available.map((plan, i) => {
            const parts = /[R][$]\s(.+)([,][\d]{2})/g.exec(maskMoney(plan.price))
            if (!parts) return null

            return (
              <div
                key={i}
                className="row plan-tile"
                onClick={() => setSelectedPlan(plan.id)} data-active={plan.id === selectedPlan}
              >
                <Plan className="icon" />
                <div className="info">
                  <p className="title">{ plan.name }</p>
                  <p className="description">
                    {
                      plan.allow_llm &&
                      <b
                        className="badge-pill margin-lr-4 default"
                        title="Permite adicionar chave da OpenIA e ativar o atendimento via inteligencia artificial"
                      >
                        <i className="fa fa-robot" style={{ margin: 0 }} /> Chat GPT
                      </b>
                    }
                    <b className="badge-pill margin-lr-4 default">
                      <i className="fa fa-calendar-alt" style={{ margin: 0 }} /> { plan.effectiveDays } dias
                    </b>
                    <b className="badge-pill margin-lr-4 secondary">{ plan.limitChannel } canais</b>
                    <b className="badge-pill margin-lr-4 secondary">{ plan.limitAttendants } atendentes</b>
                  </p>
                  {plan.allow_llm && (
                    <p className="title" style={{ fontSize: 10, marginTop: 8, fontStyle: 'italic' }}>
                      Requer conta ChatGPT com créditos de API disponíveis
                    </p>
                  )}
                </div>
                <div className="price">
                  <div className="main">R$ <b>{ parts[1] }</b>{ parts[2] }</div>
                  { plan.priceOld > plan.price && <div className="old">{ maskMoney(plan.priceOld) }</div> }
                </div>

                { plan.bestOffer && <div className="best-offer">Melhor Oferta</div> }
              </div>
            )
          })
        }

        {
          isUserOnFreePlan && (
            <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}>
              Você só pode contratar um plano quando passar seu período gratuito!
            </p>
          )
        }

        <div className="row justify-end buttons margin-top-16">
          <span className="button secondary" onClick={onClose}>Fechar</span>
          <button
            onClick={handleCreate}
            className="button gradient"
            disabled={selectedPlan === 0 || isUserOnFreePlan}
            style={{ cursor: isUserOnFreePlan ? 'not-allowed' : 'pointer' }}
          >
              Contratar
          </button>
        </div>
      </div>
    </div>
  )
}

export default InvoiceForm
