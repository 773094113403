import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { finishConversation, getConversationList, setMessageRead } from 'panel/conversation/redux/actions'
import { ApplicationState } from 'AppReducer'

import Modal from 'util/modules/Modal'
import { Conversation } from 'panel/conversation/redux/types'
import { BotNodeType, getChatBot } from 'panel/chat-bot/service/chat-bot.service'

const FinishConversation: React.FC<{ conversation: Conversation }> = ({ conversation }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { data: chatBot, isFetching } = getChatBot()
  const hasSurvey = !!chatBot?.nodes.some(node => node.type === BotNodeType.SURVEY_FORM)

  const { config } = useSelector((state: ApplicationState) => state.configReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const [isModalOpen, setModalOpen] = useState(false)
  const [sendFinishMessage, setSendFinishMessage] = useState(true)

  function handleFinish (e: React.FormEvent): void {
    e.preventDefault()

    if (selected) {
      finishConversation(selected, conversation.id, sendFinishMessage)(dispatch).then(res => {
        setMessageRead(selected, conversation.id)

        getConversationList(selected)(dispatch)
        history.push('/panel/conversation')
      })
    }
  }

  useEffect(() => {
    if (config) setSendFinishMessage(config.shouldSendFinishConversationMessage)
  }, [config])

  const qtd = conversation.attendants.length - 1

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={() => setModalOpen(false)}>
        <form className="panel" style={{ minWidth: 350, marginTop: 24 }} onSubmit={handleFinish} >
          <h2><i className="fa fa-times" style={{ marginLeft: 0 }} /> Finalizar Atendimento</h2>

          <p>Tem certeza que deseja continuar?</p>

          {
            !hasSurvey && <label>
              <input
                readOnly
                type="checkbox"
                checked={sendFinishMessage}
                onClick={() => setSendFinishMessage(!sendFinishMessage)}
              />
              Enviar mensagem de finalização
            </label>
          }

          {
            conversation.attendants.length > 1 &&
            <p style={{ color: 'var(--red)' }}>
              {
                qtd === 1
                  ? <>Atenção: ainda existe <b>{qtd} atendente</b> neste chat</>
                  : <>Atenção: ainda existem <b>{qtd} atendentes</b> neste chat</>
              }
            </p>
          }

          <div className="form-control">
            <button className="primary col-12">Finalizar</button>
          </div>
        </form>

      </Modal>
      <span
        style={{ color: '#1f1f1f' }}
        title="Finalizar Atendimento"
        className="button white bold"
        onClick={() => setModalOpen(true)}
      >
        <i className="fas fa-user-check highlight-text" /> <b className='white'>Finalizar</b>
      </span>
    </>
  )
}

export default FinishConversation
