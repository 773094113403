import { useQuery } from '@tanstack/react-query'

import { Api } from 'util/services/api'

export const getChatBotSurvey = (query: { after: string, before: string }) => {
  return useQuery<GetSurveyReportOutput>(
    ['chat-bot-survey', query],
    async () => {
      const { data } = await Api.get<GetSurveyReportOutput>('/bot/survey/report', query)
      return data
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      initialData: { conversations: [], surveys: { count: [], avg: [] }, attendants: [] }
    }
  )
}

export type GetSurveyReportOutput = {
  conversations: { count: number, date: string }[],
  surveys: {
    count: { count: number, date: string }[],
    avg: { avg: number, date: string }[],
  },
  attendants: {
    name: string,
    date: string,
    average: { survey: number, conversationTime: number }
  }[]
}
