import moment from 'moment-timezone'

const TZ = 'America/Sao_Paulo'
const TZTime = '-03:00'
const DATE_STR_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ'

export class DateObject {
  private moment: moment.Moment

  get value () {
    return this.moment.toDate()
  }

  set value (date: Date) {
    this.moment = moment(date).tz(TZ)
  }

  get period () {
    const hour = this.moment.hour()

    if (hour >= 0 && hour < 12) return 'bom dia'
    if (hour >= 12 && hour < 18) return 'boa tarde'
    if (hour >= 18 && hour < 24) return 'boa noite'
  }

  constructor () {
    this.moment = moment.tz(TZ)
  }

  public clone (): DateObject {
    return DateObject.loadFromDate(this.value)
  }

  public getSemester () {
    return this.moment.month() <= 5 ? 1 : 2
  }

  public getQuarter () {
    return Math.floor(this.moment.month() / 3) + 1
  }

  public getEndOfQuarter () {
    const year = this.moment.year()
    const quarter = this.getQuarter()

    const endOfQuarters = ['31', '30', '30', '31']
    const monthOfQuarters = ['03', '06', '09', '12']

    const dayEndOfQuarter = endOfQuarters[quarter - 1]
    const monthEndOfQuarter = monthOfQuarters[quarter - 1]
    const endOfQuarter = DateObject.loadFromString(`${year}-${monthEndOfQuarter}-${dayEndOfQuarter}`)

    return endOfQuarter
  }

  public getStartOfSemester () {
    const year = this.moment.year()
    const semester = this.getSemester()

    const monthOfSemesters = ['06', '12']

    const monthEndOfSemester = monthOfSemesters[semester - 1]
    const endOfSemester = DateObject.loadFromString(`${year}-${monthEndOfSemester}-01`)

    return endOfSemester
  }

  public getEndOfSemester () {
    const year = this.moment.year()
    const semester = this.getSemester()

    const endOfSemesters = ['30', '31']
    const monthOfSemesters = ['06', '12']

    const dayEndOfSemester = endOfSemesters[semester - 1]
    const monthEndOfSemester = monthOfSemesters[semester - 1]
    const endOfSemester = DateObject.loadFromString(`${year}-${monthEndOfSemester}-${dayEndOfSemester}`)

    return endOfSemester
  }

  public daysToEndQuarter () {
    return this.getEndOfQuarter().diffInDays(this)
  }

  public monthsToEndQuarter () {
    return this.getEndOfQuarter().diffInMonths(this)
  }

  public daysToEndSemester () {
    return this.getEndOfSemester().diffInDays(this)
  }

  public monthsToEndSemester () {
    return this.getEndOfSemester().diffInMonths(this)
  }

  public monthsToEndYear () {
    const endOfYear = DateObject.loadFromString(`${this.moment.year()}-12-31`)
    return endOfYear.diffInMonths(this)
  }

  public setAsFirstDay () {
    this.moment.set('date', this.moment.startOf('month').date())
    this.moment.set('hours', 0)
    this.moment.set('minutes', 0)
    this.moment.set('seconds', 0)
    this.moment.set('millisecond', 0)

    return this
  }

  public setAsLastDay () {
    this.moment.set('date', this.moment.endOf('month').date())
    this.moment.set('hours', 23)
    this.moment.set('minutes', 59)
    this.moment.set('seconds', 59)
    this.moment.set('millisecond', 999)

    return this
  }

  public setDay (day: number) {
    this.moment.set('date', day)

    return this
  }

  public setHoursAndMinutesAndSeconds (hours: number, minutes: number, seconds: number) {
    this.moment.set('hours', hours)
    this.moment.set('minutes', minutes)
    this.moment.set('seconds', seconds)

    return this
  }

  public addMinute (minutesToAdd: number): DateObject {
    this.moment = this.moment.add(minutesToAdd, 'minutes')

    return this
  }

  public addHour (hoursToAdd: number): DateObject {
    this.moment = this.moment.add(hoursToAdd, 'hours')

    return this
  }

  public addSecond (secondsToAdd: number): DateObject {
    this.moment = this.moment.add(secondsToAdd, 'seconds')

    return this
  }

  public addDay (daysToAdd: number): DateObject {
    this.moment = this.moment.add(daysToAdd, 'days')

    return this
  }

  public addMonths (monthsToAdd: number): DateObject {
    this.moment = this.moment.add(monthsToAdd, 'months')

    return this
  }

  public addYears (yearsToAdd: number): DateObject {
    this.moment = this.moment.add(yearsToAdd, 'years')

    return this
  }

  public subtractDay (daysToSubtract: number): DateObject {
    this.moment = this.moment.subtract(daysToSubtract, 'days')

    return this
  }

  public subtractHours (hoursToSubtract: number): DateObject {
    this.moment = this.moment.subtract(hoursToSubtract, 'hours')

    return this
  }

  public subtractMinutes (minutesToSubtract: number): DateObject {
    this.moment = this.moment.subtract(minutesToSubtract, 'minutes')

    return this
  }

  public subtractMonths (monthsToSubtract: number): DateObject {
    this.moment = this.moment.subtract(monthsToSubtract, 'months')

    return this
  }

  public isLessThan (date: DateObject) {
    const now = moment(this.moment.format('YYYY-MM-DD'))
    const from = moment(date.moment.format('YYYY-MM-DD'))

    return now < from
  }

  public isGreaterThan (date: DateObject, props?: { checkTime: boolean}) {
    const format = props?.checkTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
    const now = moment(this.moment.format(format))
    const from = moment(date.moment.format(format))

    return now > from
  }

  public isLessThanOrEqual (date: DateObject) {
    const now = moment(this.moment.format('YYYY-MM-DD'))
    const from = moment(date.moment.format('YYYY-MM-DD'))

    return now <= from
  }

  public isGreaterThanOrEqual (date: DateObject) {
    const now = moment(this.moment.format('YYYY-MM-DD'))
    const from = moment(date.moment.format('YYYY-MM-DD'))

    return now >= from
  }

  public isEqual (date: DateObject) {
    const now = moment(this.moment.format('YYYY-MM-DD'))
    const from = moment(date.moment.format('YYYY-MM-DD'))

    return now.isSame(from)
  }

  public isBeforeNow () {
    const now = moment(new DateObject().moment.format('YYYY-MM-DD'))
    const from = moment(this.moment.format('YYYY-MM-DD'))

    return from.isBefore(now)
  }

  public secondsToReadableFormat (_seconds: number) {
    const hours = Math.floor(_seconds / 3600)
    const minutes = Math.floor((_seconds % 3600) / 60)
    const seconds = _seconds % 60

    const hoursText = hours > 0 ? `${hours} hora${hours > 1 ? 's' : ''}` : ''
    const minutesText = minutes > 0 ? `${minutes} minuto${minutes > 1 ? 's' : ''}` : ''
    const secondsText = seconds > 0 ? `${seconds} segundo${seconds > 1 ? 's' : ''}` : ''

    return `${hoursText} ${minutesText} ${secondsText}`.trim()
  }

  public diffInSeconds (from: DateObject) {
    return this.moment.diff(from.moment, 'seconds')
  }

  public diffInMinutes (from: DateObject) {
    return this.moment.diff(from.moment, 'minutes')
  }

  public diffInHours (from: DateObject) {
    return this.moment.diff(from.moment, 'hours')
  }

  public diffInDays (from: DateObject) {
    return this.moment.diff(from.moment, 'days')
  }

  public diffInMonths (from: DateObject) {
    return this.moment.diff(from.moment, 'months')
  }

  public toTimestamp () {
    return this.moment.toDate().getTime()
  }

  public toString () {
    return this.moment.format(DATE_STR_FORMAT)
  }

  public toBrazilianDateFormat () {
    return this.moment.format('DD/MM/YYYY')
  }

  public toBrazilianDateTimeFormat () {
    return this.moment.format('DD/MM/YYYY HH:mm:ss')
  }

  public toDateTimeString () {
    return this.moment.format('YYYY-MM-DD HH:mm:ss')
  }

  public toDateString () {
    return this.moment.format('YYYY-MM-DD')
  }

  public toDateStringOnlyNumbers () {
    return this.moment.format('YYYYMMDD')
  }

  public toFormat (format: string) {
    return this.moment.format(format)
  }

  public toHourString () {
    return this.moment.format('HH:mm')
  }

  public toMonthString () {
    return this.moment.format('MM')
  }

  public toDayString () {
    return this.moment.format('DD')
  }

  public toYearString () {
    return this.moment.format('YYYY')
  }

  /**
   * Retorna o próximo dia útil, considerando os feriados nacionais,
   */
  public nextBusinessDay () {
    const day = this.moment.day()
    const date = this.moment.date()
    const month = this.moment.month() + 1

    const formattedDate = `${date.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}`

    if (HOLIDAYS.includes(formattedDate)) this.addDay(1)

    if (day === Weekday.SUNDAY) this.addDay(1)
    if (day === Weekday.SATURDAY) this.addDay(2)

    return this
  }

  /** Adiciona n dias úteis a partir do próximo dia útil. */
  public addBusinessDay (days: number) {
    for (let i = 0; i < days; i++) {
      this.addDay(1)

      const day = this.moment.day()
      const date = this.moment.date()
      const month = this.moment.month() + 1

      const formattedDate = `${date.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}`

      if (HOLIDAYS.includes(formattedDate)) this.addDay(1)

      if (day === Weekday.SUNDAY) this.addDay(1)
      if (day === Weekday.SATURDAY) this.addDay(2)
    }

    return this
  }

  getWeekDay () {
    return this.moment.day()
  }

  isWeekDay () {
    const day = this.moment.day()
    return day !== Weekday.SATURDAY && day !== Weekday.SUNDAY
  }

  isWeekend () {
    return !this.isWeekDay()
  }

  static loadFromDate (value: Date) {
    const date = new DateObject()
    date.value = value

    return date
  }

  /** Format: YYYY-MM-DDTHH:mm:ss.SSSZ */
  static loadFromString (value: string) {
    const isDateString = /^[\d]{4}-[\d]{2}-[\d]{2}$/g
    if (isDateString.test(value)) value = `${value}T00:00:00.000${TZTime}`

    const date = new DateObject()
    date.moment = moment(value, DATE_STR_FORMAT).tz(TZ)

    if (!date.moment.isValid()) {
      throw new Error(`Data "${value}" Inválida`)
    }

    return date
  }

  /** value in milliseconds since midnight, January 1, 1970 UTC */
  static loadFromTimestamp (valueInMilliSeconds: number) {
    const date = new DateObject()
    date.moment = moment(valueInMilliSeconds).tz(TZ)

    if (!date.moment.isValid()) {
      throw new Error(`Data "${valueInMilliSeconds}" Inválida`)
    }

    return date
  }

  /**
   * date: Date
   * string: YYYY-MM-DDTHH:mm:ss.SSSZ
   * number: value in milliseconds since midnight, January 1, 1970 UTC
   * */
  static load (value: string | number | Date, fieldName?: string) {
    if (!value) throw new Error(fieldName || 'data')

    if (typeof value === 'string') return DateObject.loadFromString(value)
    if (typeof value === 'number') return DateObject.loadFromTimestamp(value)
    if (value instanceof Date) return DateObject.loadFromDate(value)

    throw new Error('Formato de data inválido')
  }

  /**
   * date: Date
   * string: YYYY-MM-DDTHH:mm:ss.SSSZ
   * number: value in milliseconds since midnight, January 1, 1970 UTC
   * */
  static loadIfExists (value: string | number | Date) {
    if (value) return DateObject.load(value)
  }

  static toString (value?: string | number | Date | DateObject) {
    if (value) {
      if (value instanceof DateObject) return value.moment.format(DATE_STR_FORMAT)
      return DateObject.load(value).moment.format(DATE_STR_FORMAT)
    }
  }

  static now () {
    return new DateObject()
  }
}

export enum Weekday {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6
}

const HOLIDAYS = [
  '01-01', // Confraternização Universal
  '07-04', // Paixão de Cristo
  '21-04', // Tiradentes
  '01-05', // Dia Mundial do Trabalho
  '07-09', // Independência do Brasil
  '12-10', // Nossa Senhora Aparecida
  '02-11', // Finados
  '15-11', // Proclamação da República
  '25-12' // Natal
]
