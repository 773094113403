import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useOnChangeOption, useRemoveOption } from './on-change-value.hook'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

type MenuOptionProps = {
  value: string,
  nodeId: string,
  handleId: string,
}
export const MenuOption: React.FC<MenuOptionProps> = ({ value, handleId, nodeId }) => {
  const onRemoveOption = useRemoveOption(nodeId, handleId)
  const onChangeOption = useOnChangeOption(nodeId)

  return (
    <div className="option-item" key={handleId}>
      <div className='option-key'>
        <div className="remove-option-item" onClick={onRemoveOption}>
          <i className="fa fa-times"></i>
        </div>
        <input type="text" value={handleId} disabled />
      </div>
      <input
        type="text"
        value={value}
        placeholder='Digite uma opção'
        onChange={evt => onChangeOption(handleId, evt.target.value)}
      />
    </div>
  )
}

export const DndProviderWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const context = useRef(null)
  const dndArea = context.current
  const html5Options = useMemo(() => ({ rootElement: dndArea }), [dndArea])

  const [isContextLoaded, setContextLoaded] = useState(false)

  useEffect(() => {
    setContextLoaded(!!context.current)
  }, [context])

  return (
    <div ref={context} style={{ display: 'flex' }}>
      {isContextLoaded && (
        <DndProvider backend={HTML5Backend} options={html5Options}>
          {children}
        </DndProvider>
      )}
    </div>
  )
}
