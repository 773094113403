import StartNode from './start.node'
import SurveyNode from './survey.node'

import MenuNode from './menu.node'
import SurveyFormNode from './survey-form.node'

import FillDataNode from './fill-data.node'
import SendTextNode from './send-text.node'
import setPersonTag from './set-person-tag'
import RedirectSectorNode from './redirect-sector.node'
import SendAttachmentNode from './send-attachment.node'
import setConversationTag from './set-conversation-tag'
import RedirectAttendantNode from './redirect-attendant.node'
import AttendantRouletteNode from './attendant-roulette.node'
import FinishConversationNode from './finish-conversation.node'

export const nodeTypes = {
  start: StartNode,
  survey: SurveyNode,

  menu: MenuNode,
  surveyForm: SurveyFormNode,

  fillData: FillDataNode,
  sendText: SendTextNode,
  sendAttachment: SendAttachmentNode,
  redirectSector: RedirectSectorNode,
  redirectAttendantNode: RedirectAttendantNode,
  finishConversation: FinishConversationNode,

  attendantRoulette: AttendantRouletteNode,

  setPersonTag: setPersonTag,
  setConversationTag: setConversationTag
}
