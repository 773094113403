import { Id } from 'util/types/index'
import { Tag } from 'panel/tag/redux/types'
import { User } from 'panel/user/redux/types'
import { Store } from 'panel/store/redux/types'
import { Sector } from 'panel/sector/redux/types'
import { Channel } from 'panel/channel/redux/types'
import { Contact } from 'panel/person/redux/types/contact'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'
import { Attendant } from 'panel/attendant/redux/types'

/**
 * Data Types
 */

export enum MessageStatus {
  DELETED = 'DELETED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  SEEN = 'SEEN',
  PLAYED = 'PLAYED'
}

export interface Message {
  id: number,
  status: keyof typeof MessageStatus,
  reference?: string,
  referenced?: {
    id: number,
    name: string,
    message: string
  },
  sender: {
    type: 'BOT' | 'CLIENT' | 'ATTENDANT',
    name: string,
    picture?: string
  },
  attachment?: {
    type: 'PICTURE' | 'VIDEO' | 'AUDIO' | 'FILE' | 'LOCATION' | 'CONTACT',

    // Media
    url?: string
    fileName?: string
    thumbnail?: string,

    // Location
    latitude?: number,
    longitude?: number,

    // Contact
    name?: string,
    number?: string
  },
  message: string,
  previousMessage?: string,
  errorMessage: string,
  createdAt: string
}

export interface Conversation {
  id: number,
  counter?: number,
  protocol: number,
  status: 'IDLE' | 'QUEUE' | 'RUNNING' | 'FINISHED'
  isAutomated: boolean,
  isPrivate: boolean,
  privateBy: Partial<User>,
  client?: Partial<Contact>,
  clientValue: string,
  channel: Partial<Channel>,
  sector: Partial<Sector>,
  store: Partial<Store>,
  tag: Partial<Tag>,
  messages: Message[],
  unreadMessages: number,
  attendants: Partial<Attendant>[],
  tick: string,
  createdAt: string,
  updatedAt?: string
}

export type SendBrowserNotificationProps = {
  userId: number,
  conversationId: number,

  icon: string
  title: string,
  message: string,
}

/**
 * State Type
 */

export interface ConversationState {
  readonly pastMessages: { [mainConversationId: number]: { [id: number]: Message[] } }
  readonly conversation: { [id: number]: Conversation }
  readonly conversations: Conversation[]
  readonly conversationsFiltered: Conversation[]
}

/**
 * Action Enum Types
 */

export enum ConversationTypes {
  CONVERSATION_RESET = 'CONVERSATION/RESET',
  CONVERSATION_GET = 'CONVERSATION/GET',
  CONVERSATION_UPDATE = 'CONVERSATION/UPDATE',
  CONVERSATION_GET_LIST = 'CONVERSATION/GET/LIST',
  CONVERSATION_GET_LIST_FILTERED = 'CONVERSATION/GET/LIST/FILTERED',

  CONVERSATION_NEW_MESSAGE = 'CONVERSATION/NEW/MESSAGE',
  CONVERSATION_NOTIFICATION = 'CONVERSATION/NEW/NOTIFICATION',
  CONVERSATION_CLEAR_COUNTER = 'CONVERSATION/CLEAR/COUNTER',
  CONVERSATION_UPDATE_MESSAGE = 'CONVERSATION/UPDATE/MESSAGE',
  CONVERSATION_UPDATE_UNREAD = 'CONVERSATION/UPDATE/UNREAD',

  CONVERSATION_PAST_MESSAGE = 'CONVERSATION/PAST/MESSAGE',
}

/**
 * Action Types
 */

interface ResetAction {
  type: typeof ConversationTypes.CONVERSATION_RESET,
}

interface GetAction {
  type: typeof ConversationTypes.CONVERSATION_GET,
  payload: Conversation
}

interface UpdateAction {
  type: typeof ConversationTypes.CONVERSATION_UPDATE,
  payload: Conversation
}

interface GetListAction {
  type: typeof ConversationTypes.CONVERSATION_GET_LIST,
  payload: Conversation[]
}

interface GetListFilteredAction {
  type: typeof ConversationTypes.CONVERSATION_GET_LIST_FILTERED,
  payload: Conversation[]
}

interface NewMessageAction {
  type: typeof ConversationTypes.CONVERSATION_NEW_MESSAGE,
  payload: Message,
  id: Id
}

interface NewBrowserNotification {
  type: typeof ConversationTypes.CONVERSATION_NOTIFICATION,
  payload: SendBrowserNotificationProps,
}

interface UpdateMessageAction {
  type: typeof ConversationTypes.CONVERSATION_UPDATE_MESSAGE,
  conversationId: Id
  message: Partial<Message>
}

interface UpdateMessageUnreadAction {
  type: typeof ConversationTypes.CONVERSATION_UPDATE_UNREAD,
  conversationId: Id
  unreadMessages: number
}

interface ClearCounterAction {
  type: typeof ConversationTypes.CONVERSATION_CLEAR_COUNTER,
  id: Id
}

interface GetPastMessage {
  type: typeof ConversationTypes.CONVERSATION_PAST_MESSAGE,
  mainConversationId: number,
  pastConversationId: number,
  messages: Message[]
}

export type ConversationActionTypes = (
  AlertActionTypes | ResetAction | UpdateAction | GetAction | GetListAction |
  NewMessageAction | ClearCounterAction | UpdateMessageAction | GetListFilteredAction |
  GetPastMessage | UpdateMessageUnreadAction | NewBrowserNotification
)
