import React from 'react'

type Props = { active: Tab, unsaved?: Tab, onSelect: (tab: Tab) => void }
export type Tab = 'DATA' | 'COMPLEMENTARY' | 'SECTOR' | 'TIME-TABLE' | 'PASSWORD'
const AttendantTab: React.FC<Props> = ({ active, unsaved, onSelect }) => {
  const items: { tab: Tab, label: string }[] = [
    { tab: 'DATA', label: 'Dados' },
    { tab: 'COMPLEMENTARY', label: 'Dados Complementares' },
    { tab: 'PASSWORD', label: 'Senha' }
  ]

  return (
    <div className="row attendant-tab">
      {
        items.map((item, i) => {
          const isUnsaved = item.tab === unsaved
          const isActive = !unsaved || isUnsaved

          return (
            <span
              key={i}
              onClick={() => onSelect(item.tab)}
              className={`tab-option ${active === item.tab ? 'active' : ''}`}
              style={{ opacity: isActive ? 1 : 0.4, pointerEvents: isActive ? 'all' : 'none' }}
            >
              { item.label }
              { isUnsaved && <i className="fa fa-circle" title="Não salvo" style={{ color: 'red' }} /> }
            </span>
          )
        })
      }
    </div>
  )
}

export default AttendantTab
