import update from 'immutability-helper'
import React, { memo, useCallback } from 'react'
import { Handle, NodeProps, Position, useReactFlow } from 'reactflow'

import { Droppable } from '../drag-and-drop/droppable'
import { DeleteNode } from './delete-node'
import { FormControlNode, ItemWithIcon } from '../form-control.node'
import { DndProviderWrapper, MenuOption } from './helpers/menu'
import { useOnChangeLabel, useOnChangeValue } from './helpers/on-change-value.hook'
import { NodeOptionItem, useAddOption, useSetOptions } from './helpers/on-change-value.hook'

interface Props extends NodeProps {
  data: {
    label: string,
    value: string,
    options: NodeOptionItem[]
  }
}
const SurveyFormNode: React.FC<Props> = ({ id, data }) => {
  const { getNode } = useReactFlow()

  const onAddOption = useAddOption(id)
  const onSetOptions = useSetOptions(id)
  const onChangeValue = useOnChangeValue(id)
  const onChangeLabel = useOnChangeLabel(id)

  const handleAddOption = () => {
    const node = getNode(id)
    if (!node) return

    onAddOption({
      key: String(node.data.options.length + 1),
      value: ''
    })
  }

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    onSetOptions((prevOptions) => {
      const result = update(prevOptions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevOptions[dragIndex]]
        ]
      })

      return JSON.parse(
        JSON.stringify(
          result.map((item, i) => ({ ...item, key: String(i + 1) }))
        )
      )
    })
  }, [])

  return (
    <div className="node" style={{ width: 300 }}>
      <DeleteNode id={id} />
      <FormControlNode>
        <ItemWithIcon icon="fa-question-circle">
          Avaliação de Atendimento
        </ItemWithIcon>
      </FormControlNode>

      <FormControlNode>
        <textarea
          rows={4}
          style={{ resize: 'none' }}
          onChange={onChangeValue}
          value={data.value}
          placeholder='Informe a mensagem a ser enviada'
        ></textarea>
        <p style={{ gridRow: 2, gridColumn: '2/3', textAlign: 'right' }}>{data.value?.length ?? 0}/500</p>
      </FormControlNode>
      <div className='px custom-drag-handle row text-left pb'>
        <p><b>Mensagem do sistema:</b></p>
        <p>Avalie de 1 a 5, sendo 1 a pior nota e 5 a melhor nota.</p>
      </div>

      <Handle
        id="left"
        type="target"
        position={Position.Left}
        style={{ left: -5, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="top"
        type="target"
        position={Position.Top}
        style={{ top: 3, width: 12, height: 12, background: '#00ECBC' }}
      />
    </div>
  )
}

export default memo(SurveyFormNode)
