import React, { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'

import './styles/SurveyReport.scss'

import { maskDate } from 'util/masks'
import { DateObject } from 'util/DateObject'
import { getChatBotSurvey } from './services/chat-bot-survey.service'

const start = DateObject.now().subtractDay(30)
const end = DateObject.now()

const generateCsv = (props: { name: string, header: string[], rows: string[][] }) => {
  let csvContent = ''
  const fileName = props.name

  const header = props.header

  csvContent += header.join(';')
  csvContent += '\n'

  props.rows.forEach(itens => {
    const line = itens.join(';')
    csvContent += line
    csvContent += '\n'
  })

  const universalBOM = '\uFEFF'
  const a = window.document.createElement('a')
  a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvContent))
  a.setAttribute('download', fileName)
  window.document.body.appendChild(a)
  a.click()
  window.document.body.removeChild(a)
}

const Survey: React.FC = () => {
  const query = new URLSearchParams(window.location.search)

  const { data = { conversations: [], attendants: [], surveys: { avg: [], count: [] } } } = getChatBotSurvey({
    after: query.get('after') || start.toDateString(),
    before: query.get('before') || end.toDateString()
  })

  const dateEnToBr = (date: string | null) => date?.split('-').reverse().join('/')
  const dateBrToEn = (date: string | null) => date?.split('/').reverse().join('-')

  const [filter, setFilter] = React.useState({
    after: dateEnToBr(query.get('after')) || start.toBrazilianDateFormat(),
    before: end.toBrazilianDateFormat()
  })

  const handleDownloadCsv = () => {
    const header = ['Atendente', 'Nota', 'Tempo de atendimento', 'Data']
    const rows = data.attendants.map((attendant) => [
      attendant.name,
      attendant.average.survey.toString(),
      formatTime(attendant.average.conversationTime)
    ])

    generateCsv({ name: 'atendimentos.csv', header, rows })
  }

  const handleFilter = () => {
    const after = dateBrToEn(filter.after)
    const before = dateBrToEn(filter.before)
    if (!before || !after) return

    query.set('after', after)
    query.set('before', before)
    window.location.search = query.toString()
  }

  const renderStars = (rating: number) => {
    const stars = []
    const fullStars = Math.floor(rating)
    const hasHalfStar = rating % 1 !== 0
    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<i key={i} className="fa fa-star" />)
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<i key={i} className="fa fa-star-half-alt" />)
      } else {
        stars.push(<i key={i} className="far fa-star" />)
      }
    }
    return stars
  }

  const formatTime = (minutes: number): string => {
    const hrs = Math.floor(minutes / 60)
    const mins = minutes % 60
    const pad = (n: number) => n.toString().padStart(2, '0')
    return `${pad(hrs)}:${pad(mins)}:00`
  }

  useEffect(() => {
    const after = dateEnToBr(query.get('after'))
    const before = dateEnToBr(query.get('before'))

    if (!after || !before) return

    setFilter({ after, before })
  }, [])

  return (
    <div className="row justify-end survey-report-container" style={{ gap: 16 }}>
      <div className="row align-end justify-end" style={{ width: 'auto' }}>
        <div className="form-control" style={{ width: 200 }}>
          <label>Inicio</label>
          <input
            type="text"
            value={filter.after}
            onChange={(e): void => setFilter({ ...filter, after: maskDate(e.target.value) })}
          />
        </div>
        <div className="form-control" style={{ width: 200 }}>
          <label>Fim</label>
          <input
            type="text"
            value={filter.before}
            onChange={(e): void => setFilter({ ...filter, before: maskDate(e.target.value) })}
          />
        </div>
        <button className="gradient" onClick={handleFilter} style={{ height: 48 }}>
          <i className="fa fa-filter" />Filtrar
        </button>
        <button className="margin-left-8" onClick={handleDownloadCsv} style={{ height: 48 }}>
          <i className="fa fa-download" /> CSV
        </button>
      </div>

      <div className="row padding-top-8 chart-content panel">
        <p className="grow" style={{ fontSize: 18 }}>Atendimentos</p>
        <ReactApexChart
          type='bar'
          height={300}
          style={{ width: '100%' }}
          series={[{
            name: 'Atendimentos',
            data: data.conversations.map((c: any) => c.count)
          }]}
          options={{
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                borderRadius: 5,
                borderRadiusApplication: 'end'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: data.conversations.map((c: any) => c.date)
            },
            yaxis: {
              title: {
                text: 'Quantidade'
              }
            },
            fill: {
              opacity: 1
            }
          }}
        />
      </div>
      <div className="row padding-top-8 chart-content panel">
        <p className="grow" style={{ fontSize: 18 }}>Quantidade de avaliações</p>
        <ReactApexChart
          type='area'
          height={300}
          style={{ width: '100%' }}
          series={[{
            name: 'Atendimentos',
            data: data.surveys.count.map((c: any) => c.count)
          }]}
          options={{
            fill: {
              type: 'gradient',
              gradient: {
                gradientToColors: ['#4158D0', '#C850C0'],
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              }
            },
            xaxis: {
              categories: data.surveys.count.map((c: any) => c.date)
            },
            yaxis: {
              title: {
                text: 'Quantidade'
              }
            }
          }}
        />
      </div>
      <div className="row padding-top-8 chart-content panel">
        <p className="grow" style={{ fontSize: 18 }}>Média de avaliações</p>
        <ReactApexChart
          type='area'
          height={300}
          style={{ width: '100%' }}
          series={[{
            name: 'Média',
            data: data.surveys.avg.map((c: any) => c.avg)
          }]}
          options={{
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                gradientToColors: ['#0093E9', '#80D0C7'],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              }
            },
            xaxis: {
              categories: data.surveys.avg.map((c: any) => c.date)
            },
            yaxis: {
              title: {
                text: 'Média'
              }
            }
          }}
        />
      </div>

      <div className="list">
        <div className="list-header">
          <div>Atendente</div>
          <div>Nota</div>
          <div>Tempo de atendimento</div>
        </div>

        {
          data.attendants.length === 0 &&
          <div className="list-row">
            <div className="grid-span" style={{ height: 0 }}>Nenhum atendimento</div>
          </div>
        }

        {
          data.attendants.map((attendant) => {
            return (
              <div className="list-row white" key={attendant.name}>
                <div data-content="Atendente">{attendant.name}</div>
                <div data-content="Nota">
                  {renderStars(attendant.average.survey)}
                </div>
                <div data-content="Tempo de atendimento">
                  {formatTime(attendant.average.conversationTime)}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Survey
