import { uuidv7 } from 'uuidv7'
import { Edge, Node } from 'reactflow'
import { getStoreId } from 'util/hooks'
import { useDispatch } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Api } from 'util/services/api'
import { clearAlert, createAlert } from 'util/modules/Alert/redux/actions'

export const getId = () => uuidv7()

export const saveChatBot = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    async (form: { nodes: Node[], connections: Edge[] }) => Api.put('/bot', form),
    {
      onSuccess: async ({ statusCode, message = 'Bot Salvo' }) => {
        await queryClient.invalidateQueries(['new-chat-bot'])
        createAlert({ message, code: statusCode })(dispatch)
      },
      onError: (error: Error) => {
        createAlert({ message: error.message || 'Ocorreu um erro ao salvar o bot', code: 500 })(dispatch)
      },
      onSettled: () => clearAlert(3_000)(dispatch)
    }
  )
}

export const getChatBot = () => {
  return useQuery<GetBotResponse>(
    ['new-chat-bot'],
    async () => {
      const { data } = await Api.get<GetBotResponse>('/bot')
      return data || { nodes: [], connections: [] }
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      initialData: { nodes: [], connections: [] }
    }
  )
}

type GetBotResponse = {
  nodes: BotNodeDto[],
  connections: BotConnectionDto[]
}

export enum BotConnectionType {
  BOT = 'BOT',
  OPTION = 'OPTION',
}

export type BotConnectionDto = {
  id: string,
  meta: string

  sourceId: string
  sourceType: BotConnectionType

  targetId: string
  targetType: BotConnectionType
}

export enum BotNodeType {
  START = 'START',
  SURVEY = 'SURVEY',

  MENU = 'MENU',
  SURVEY_FORM = 'SURVEY_FORM',

  FILL_CPF = 'FILL_CPF',
  FILL_CNPJ = 'FILL_CNPJ',
  FILL_NAME = 'FILL_NAME',
  FILL_EMAIL = 'FILL_EMAIL',
  SEND_TEXT = 'SEND_TEXT',
  ATTENDANT_ROULETTE = 'ATTENDANT_ROULETTE',
  SEND_ATTACHMENT = 'SEND_ATTACHMENT',
  REDIRECT_SECTOR = 'REDIRECT_SECTOR',
  REDIRECT_ATTENDANT = 'REDIRECT_ATTENDANT',
  FINISH_CONVERSATION = 'FINISH_CONVERSATION',

  SET_PERSON_TAG = 'SET_PERSON_TAG',
  SET_CONVERSATION_TAG = 'SET_CONVERSATION_TAG',
}

export type BotNodeDto = {
  id: string,
  storeId: number,

  type: BotNodeType,
  value: string,
  label: string,

  meta: string,

  options: BotOptionDto[]
}

export type BotOptionDto = {
  id: string,
  key: string,
  value: string,
}
